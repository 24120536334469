.header {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1rem 6rem;
    background-color: var(--color-bg);
    color: var(--color-blue);
}

.header h1 {
    padding: 5px;
    padding-bottom: 0px;
    transition: 0.5s;
    //border: dashed 2px var(--color-orange);
}
.header h1:hover {
    color: var(--color-orange);
    padding: 10px;
    //border: dashed 2px var(--color-blue);
    margin: 5px;
}

.header__logo {
    margin-left: 1.75rem;
}

.header .email a {
    color: var(--color-blue);
    position: absolute; top: 20px; right:30px;
    transition: .2s;
}
.header .email a:hover {
    color: var(--color-orange);
    border-bottom: dashed 3px var(--color-blue);
}

@media (max-width: 800px){
    .header .email a {
        position: absolute; top: 10.5rem; left: 5rem;
        font-family: 'Regular';
    }
    .header .email a:hover {
        color: var(--color-orange);
        border-bottom: 0px;
    }
    .header h1:hover {
        color: var(--color-orange);
        padding: 5px;
        margin: 2px;
    }
}


body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    background-color: var(--color-bg);
}

a {
    color: var(--color-SLblue);
    text-decoration: none;
}

.flex-parent {
    display: flex;
    padding: 3rem 4.5rem;
}

.flex-item {
    padding: .5rem;
    flex: 1;
}

.flex-item h2
{
    border-bottom: 2px var(--color-blue) solid;
}

li a {
    padding: 0.3rem;
    transition: .5s;
}

li a:hover {
    color: var(--color-orange);
    padding: 5px;
}

li a c {
    color: var(--color-Lorange);
}

@media (max-width: 800px) {
    .flex-parent {
        flex-direction: column;
    }
    h1 {
        font-size: 3rem;
        font-family: 'SemiBold';
      }
      
      h2 {
        font-size: 2.5rem;
        font-family: 'SemiBold';
        color: var(--color-Dblue);
      }
      
      h3 {
        font-size: 2rem;
        font-family: 'Regular';
        color: var(--color-Dblue);
      }
      
      ul {
        font-size: 1.3rem;
        font-family: 'SemiBold';
        color: var(--color-blue);
      }
}



